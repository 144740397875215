import React from 'react';
import './ContactLinks.scss';

import { FiMail } from 'react-icons/fi';
import { AiFillLinkedin } from 'react-icons/ai';
import { AiFillGithub } from 'react-icons/ai';
import { AiFillPhone } from 'react-icons/ai';



export default function ContactLinks() {

    const iconSize = "2rem";

    function iconColorOnEnter(e) {
        e.currentTarget.id = "contactLinkAnim1";
    }

    function iconColorOnLeave(e) {
        e.currentTarget.id = "contactLinkAnim2";
    }

    return (
        <div id="contactLinksContainer">
            <a href="mailto:jordiamir@gmail.com" target="_blank" className="contactLink">
                <FiMail size={iconSize} title="Email" className="contactLinkIcon" onMouseEnter={iconColorOnEnter} onMouseLeave={iconColorOnLeave} />
            </a>
            <a href="https://www.linkedin.com/in/yarden-amir-96348898/" target="_blank" className="contactLink">
                <AiFillLinkedin size={iconSize} title="Linkedin" className="contactLinkIcon" onMouseEnter={iconColorOnEnter} onMouseLeave={iconColorOnLeave} />
            </a>
            <a href="https://github.com/Yarden84" target="_blank" className="contactLink">
                <AiFillGithub size={iconSize} title="GitHub" className="contactLinkIcon" onMouseEnter={iconColorOnEnter} onMouseLeave={iconColorOnLeave} />
            </a>
            <a href="tel:050-6666-031" target="_blank" className="contactLink">
                <AiFillPhone size={iconSize} title="Phone" className="contactLinkIcon" onMouseEnter={iconColorOnEnter} onMouseLeave={iconColorOnLeave} />
            </a>
        </div>
    );
}

