import React, { useState } from 'react';
import './Contact.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';


import ThankYou from './ThankYou/ThankYou';


export default function Contact() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [messageSent, setMessageSent] = useState(false);


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }


    const cancelRedirect = () => {
        setMessageSent(false);
    }


    function handleSubmit(e) {
        console.log(`name = ${name}`);
        console.log(`email = ${email}`);
        e.preventDefault();
        if (name === "") {
            document.getElementById("formName").classList.add("notValid");
            document.getElementById("formName").placeholder = "*Please enter your name";
        }
        else if (!formValidation(email)) {
            document.getElementById("formEmail").classList.add("notValid");
            document.getElementById("formEmail").placeholder = "*Please enter a valid email";
        }
        else {
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact", name, email, message })
            })
                .then(() => console.log("Success!"))
                .catch(error => alert(error));

            setMessageSent(true);
        }

    }

    function formValidation(email) {
        const emailRegEx = /\S+@\S+\.\S+/;
        return emailRegEx.test(email);
    }


    function handleName(e) {
        setName(e.target.value);
        console.log(`name = ${name}`);
    }
    function handleEmail(e) {
        setEmail(e.target.value);
    }
    function handleMessage(e) {
        setMessage(e.target.value);
    }




    return (
        <Router>
            <div id="contact">
                <Switch>
                    <Route exact path="/">
                        <h1>Let's Talk!</h1>
                        <form name="contact" id="contactForm" onSubmit={handleSubmit}>
                            <div className="contactField">
                                <input type="text" name="name" placeholder="Name" id="formName" value={name} onChange={handleName} />
                            </div>
                            <div className="contactField">
                                <input type="email" name="email" placeholder="Email" id="formEmail" value={email} onChange={handleEmail} />
                            </div>
                            <div className="contactField">
                                <textarea name="message" placeholder="Your message" id="formText" value={message} onChange={handleMessage}></textarea>
                            </div>

                            <input type="submit" value="Send" id="formSubmitBtn" />

                            {messageSent ? <Redirect push to="/messageSent" /> : <Redirect to="/" />}
                        </form>
                    </Route>

                    <Route exact path="/messageSent">
                        <ThankYou cancelRedirect={cancelRedirect} />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

