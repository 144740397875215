import React, { useState, useEffect, useLayoutEffect } from 'react';
import './Project.scss';

import Aos from 'aos';
import 'aos/dist/aos.css';

export default function Project(props) {
    const title = props.title;
    const link = props.link;
    const desktop = props.desktop;
    const mobile = props.mobile;
    const description = props.description;
    const icons = props.icons;
    const id = props.id;

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const width = useWindowSize();

    let aosEffect;

    if (width > 768) {
        aosEffect = id % 2 === 0 ? "fade-right" : "fade-left";
    }
    else {
        aosEffect = "slide-up";
    }



    const currentDevice = desktop === "" ? mobile : desktop;

    const [device, setDevice] = useState(currentDevice);

    const DesktopImg = () => (
        <img src={desktop} alt="device" className="device" />
    )
    const MobileImg = () => (
        <img src={mobile} alt="device" className="device" />
    )

    function scaleUpProject(e) {
        e.currentTarget.id = "projectHoverAnim1";
    }

    function scaleDownProject(e) {
        e.currentTarget.id = "projectHoverAnim2";
    }


    function changeDevice(e) {
        if (device === desktop) {
            e.currentTarget.id = 'btnAnimToRight';
            setTimeout(() => setDevice(mobile), 90);
        }
        else {
            e.currentTarget.id = 'btnAnimToLeft';
            setTimeout(() => setDevice(desktop), 90);
        }
    }

    useEffect(() => {
        Aos.init({ duration: 500, once: true });
    }, []);

    return (
        <div data-aos={aosEffect} className="projectContainer" onMouseEnter={scaleUpProject} onMouseLeave={scaleDownProject}>
            <h1>{title}</h1>
            <div className="projectData">

                <div className="projectScreens">
                    <a href={link} className="" target="_blank">
                        {/* <img src={device} alt="device" className="device" /> */}
                        {device === desktop ? <DesktopImg /> : <MobileImg />}
                    </a>
                </div>
                <div className="projectLine"></div>
                <div className="projectDescription">
                    <p>{description}</p>
                    <div className="icons">{icons}</div>
                </div>
                <div className="projectBtns" id={desktop !== "" ? "" : "oneBtnFlex"}>
                    {desktop !== "" ?
                        <div className="switchViewContainer">
                            <p>Desktop</p>
                            <div className="switchViewBtnContainer">
                                <div className="switchViewBtn" onClick={changeDevice}></div>
                            </div>
                            <p>Mobile</p>
                        </div>
                        : ""}
                    <a href={link} className="viewSiteBtn" target="_blank">
                        <button>View Site</button>
                    </a>

                </div>

            </div>
        </div>
    );
}

