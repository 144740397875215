import React from 'react';
import './Navbar.scss';

export default function Navbar() {
    return (
        <nav id="navbarContainer">
            <a href="#projects">
                <button id="projectsLink">Stuff I Made</button>
            </a>
            <a href={require('../../assets/Yarden Amir - CV.pdf')} target="_blank">
                <button id="cvLink">CV</button>
            </a>
            <a href="#contact">
                <button id="contactLink">Contact Me</button>
            </a>
        </nav>
    );
}

