import React from 'react';

import { DiHtml5 } from 'react-icons/di';
import { DiCss3 } from 'react-icons/di';
import { DiJavascript } from 'react-icons/di';
import { FaAngular } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import { FaSass } from 'react-icons/fa';
import { FaWordpress } from 'react-icons/fa';
import { FaNodeJs } from 'react-icons/fa';
import { DiMongodb } from 'react-icons/di';
import { BsBootstrapFill } from 'react-icons/bs';
import { FaPhp } from 'react-icons/fa';
import { GrHeroku } from 'react-icons/gr';
import TypescriptIcon from '../../assets/svg/TypescriptIcon'


export const data = [
    {
        title: 'dosgamesworld.com',
        link: '//www.dosgamesworld.com',
        desktop: require('../../assets/images/dos.png'),
        mobile: require('../../assets/images/dosMobile.png'),
        description: 'A site dedicated to vintage Dos (and some non-Dos) games. Built with a friend during the long Corona quarantine. Created with Angular.',
        icons: [
            <FaAngular color="#dd0031" size="2rem" title="Angular" className="projectIcon" />,
            <TypescriptIcon size="2rem" title="Typescript" className="projectIcon" />,
            <DiHtml5 color="#e2521c" size="2rem" title="HTML5" className="projectIcon" />,
            <FaSass color="#cd6699" size="2rem" title="Sass" className="projectIcon" />,
            <FaNodeJs color="#9cce44" size="2rem" title="Node JS" className="projectIcon" />,
            <DiMongodb color="#69b240" size="2rem" title="MongoDB" className="projectIcon" />
        ]
    },
    {
        title: 'cadr.co.il',
        link: 'https://www.cadr.co.il/',
        desktop: require('../../assets/images/cadr.png'),
        mobile: require('../../assets/images/cadrMobile.png'),
        description: 'A site made for the Center of Arbitration and Dispute Resolution. Created via WordPress, with a custom theme developed from scratch according to a studio design. ',
        icons: [
            <FaWordpress color="#21759b" size="2rem" title="Wordpress" className="projectIcon" />,
            <FaPhp size="2rem" title="PHP" className="projectIcon" />,
            <DiJavascript color="#e5a407" size="2rem" title="Javascript" className="projectIcon" />,
            <DiHtml5 color="#e2521c" size="2rem" title="HTML5" className="projectIcon" />,
            <DiCss3 color="#1973bb" size="2rem" title="CSS3" className="projectIcon" />
        ]
    },
    {
        title: 'Understand Math',
        link: 'https://understand-math.netlify.app/',
        desktop: require('../../assets/images/math.png'),
        mobile: require('../../assets/images/mathMobile.png'),
        description: 'Interactive educational website, made according to a book written by the late Israel Haizler. This version is a POC, and hopefully a more extensive version will be ready soon.',
        icons: [
            <FaReact color="#00dbfc" size="2rem" title="React JS" className="projectIcon" />,
            <DiJavascript color="#e5a407" size="2rem" title="Javascript" className="projectIcon" />,
            <DiHtml5 color="#e2521c" size="2rem" title="HTML5" className="projectIcon" />,
            <DiCss3 color="#1973bb" size="2rem" title="CSS3" className="projectIcon" />,
            <BsBootstrapFill color="#553a7d" size="2rem" title="Bootstrap" className="projectIcon" />,
        ]
    },
    {
        title: 'Jewkulele',
        link: 'https://jewkulele.netlify.app/',
        desktop: require('../../assets/images/jewkulele.png'),
        mobile: require('../../assets/images/jewkuleleMobile.png'),
        description: 'A site dedicated to a small passion of mine - the ukulele! This site contains information about the instrument, a chord engine, tuner and more. Created with Anguler.',
        icons: [
            <FaAngular color="#dd0031" size="2rem" title="Angular" className="projectIcon" />,
            <TypescriptIcon size="2rem" title="Typescript" className="projectIcon" />,
            <DiHtml5 color="#e2521c" size="2rem" title="HTML5" className="projectIcon" />,
            <DiCss3 color="#1973bb" size="2rem" title="CSS3" className="projectIcon" />,
            <BsBootstrapFill color="#553a7d" size="2rem" title="Bootstrap" className="projectIcon" />,
        ]
    },
    {
        title: 'Padlaot',
        link: 'https://padlaot.herokuapp.com/',
        desktop: '',
        mobile: require('../../assets/images/padlaotMobile.png'),
        description: 'A scoreboard for amateur soccer games. Created with Angular and deployed with Heroku.',
        icons: [
            <FaAngular color="#dd0031" size="2rem" title="Angular" className="projectIcon" />,
            <TypescriptIcon size="2rem" title="Typescript" className="projectIcon" />,
            <DiHtml5 color="#e2521c" size="2rem" title="HTML5" className="projectIcon" />,
            <FaSass color="#cd6699" size="2rem" title="Sass" className="projectIcon" />,
            <FaNodeJs color="#9cce44" size="2rem" title="Node JS" className="projectIcon" />,
            <DiMongodb color="#69b240" size="2rem" title="MongoDB" className="projectIcon" />,
            <GrHeroku color="#6762a6" size="2rem" title="MongoDB" className="projectIcon" />
        ]
    }
]