import React from 'react';
import './Footer.scss';
import { RiCopyrightLine } from 'react-icons/ri';


export default function Footer() {
    return (
        <div id="footerContainer">
            <p>Copyright </p>
            <RiCopyrightLine />
            <p>2020 - All Rights Reserved</p>
        </div>
    );
}

