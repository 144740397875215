import React from 'react';
import './App.scss';
import LightSwitch from './components/LightSwitch/LightSwitch';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Contact from './components/Contact/Contact';
import Skills from './components/Skills/Skills';
import Projects from './components/Projects/Projects';
import ArrowToTop from './components/ArrowToTop/ArrowToTop';
import ContactLinks from './components/ContactLinks/ContactLinks';
import Footer from './components/Footer/Footer';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';


function App() {

  function changeDarkMode(mode) {
    if (mode) {
      document.getElementsByClassName("appContainer")[0].id = "";
    }
    else {
      document.getElementsByClassName("appContainer")[0].id = "lightMode";
    }
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="appContainer" id="appContainer">
            <LightSwitch changeDarkMode={changeDarkMode} />
            <Header />
            <Navbar />
            <Skills />
            <Projects />
            <Contact />
            <ArrowToTop />
            <ContactLinks />
            <Footer />
          </div>
        </Route>
      </Switch>
      <Redirect to="/" />
    </Router>
  );
}

export default App;
